import React from 'react';
import { useState } from 'react';
import PricingTable from '.';

export default function PricingTableList() {
  const [tab, setTab] = useState('semester');
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links cs_style1 cs_mp0 cs_pricing_control">
        <li
          className={tab === 'semester' ? 'active' : ''}
          onClick={() => setTab('semester')}
        >
          <span>Per Semester</span>
          <span className="cs_switch"></span>
        </li>
        <li
          className={tab === 'yearly' ? 'active' : ''}
          onClick={() => setTab('yearly')}
        >
          <span>Yearly</span>
        </li>
      </ul>
      <div className="row cs_gap_y_30">
        <div className="col-lg-4">
          {tab === 'semester' && (
            <PricingTable
              title="Individual"
              subTitle="Perfect for individual learners."
              price="39"
              currency="$"
              timeline="per semester"
              features={[
                'Single user access',
                '3 AI personas',
                'Basic analytics',
                'Email support',
                '20 student interactions/semester',
              ]}
              btnText="Start Free Trial"
              btnLink="/"
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="Individual"
              subTitle="Perfect for individual learners."
              price="49"
              currency="$"
              timeline="yearly"
              features={[
                'Single user access',
                '6 AI personas',
                'Basic analytics',
                'Email support',
                '40 student interactions/year',
              ]}
              btnText="Start Free Trial"
              btnLink="/"
            />
          )}
        </div>
        <div className="col-lg-4">
          {tab === 'semester' && (
            <PricingTable
              title="Mid-sized"
              subTitle="Ideal for single classes or small departments (175 users)."
              price="4,500"
              currency="$"
              timeline="per semester"
              features={[
                'Up to 5 educator accounts',
                '10 AI personas',
                'Advanced analytics',
                'Priority support',
                '3,500 student interactions/semester',
                'LMS integration',
              ]}
              btnText="Get Started"
              btnLink="/"
              popular
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="Class/Department"
              subTitle="Ideal for single classes or small departments."
              price="5,000"
              currency="$"
              timeline="yearly"
              features={[
                'Up to 10 educator accounts',
                '20 AI personas',
                'Advanced analytics',
                'Priority support',
                '7000 student interactions/year',
                'LMS integration',
              ]}
              btnText="Get Started"
              btnLink="/"
              popular
            />
          )}
        </div>
        <div className="col-lg-4">
          {tab === 'semester' && (
            <PricingTable
              title="Institution/University"
              subTitle="Comprehensive solution for entire institutions."
              price="Contact Us"
              currency=""
              timeline="custom plans available"
              features={[
                'Unlimited educator accounts',
                'Custom AI personas',
                'Full analytics suite',
                'Dedicated support team',
                'Unlimited student interactions',
                'Advanced LMS integration',
                'Custom feature development',
              ]}
              btnText="Contact Sales"
              btnLink="/"
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="University"
              subTitle="Comprehensive solution for entire institutions."
              price="Contact Us"
              currency=""
              timeline="custom plans available"
              features={[
                'Unlimited educator accounts',
                'Custom AI personas',
                'Full analytics suite',
                'Dedicated support team',
                'Unlimited student interactions',
                'Advanced LMS integration',
                'Custom feature development',
              ]}
              btnText="Contact Sales"
              btnLink="/"
            />
          )}
        </div>
      </div>
    </div>
  );
}