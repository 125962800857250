import React from 'react';
import HeroStyle5 from '../Hero/HeroStyle5';
import Cta from '../Cta';
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';
import PostCarousel from '../Slider/PostCarousel';
import PricingTableList from '../PricingTable/PricingTableList';
import TestimonialSliderStyle2 from '../Slider/TestimonialSliderStyle2';
import FunFact from '../FunFact';
import VideoModal from '../VideoModal';
import IconboxStyle4 from '../IconBox/IconboxStyle4';
import Card from '../Card';
import CardStyle2 from '../Card/CardStyle2';
import SectionHeadingStyle2 from '../SectionHeading/SectionHeadingStyle2';
import IconBoxStyle5 from '../IconBox/IconBoxStyle5';
import BrandCarousel from '../Slider/BrandCarousel';
import { pageTitle } from '../../helpers/PageTitle';

const brandData = [
  { logoSrc: '/images/tech-startup/brand_1.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_2.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_3.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_4.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_5.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_6.svg', alt: 'Brand' },
];

const funfactData = [
  { title: 'Happy Users', number: '900' },
  { title: 'Simulations Completed', number: '6,000' },
  { title: 'Industries Served', number: '8' },
  { title: 'Custom Scenarios ', number: '50+' },
];

const testimonialData = [
  {
    text: 'ATLAS provides a very judgment free sort of environment where I can practice freely without having to feel like the person on the other end is going to think I\'m dumb if I\'m making [a] mistake',
    avatarName: 'Pharmacy Student, Monash University',
    avatarDesignation: 'History-taking Simulation',
  },
  {
    text: "We get a very wonderful set of feedback after doing a practice case and that goes through everything that you've done and analyses you even down to your body language",
    avatarName: 'Pharmacy Student, Monash University',
    avatarDesignation: 'History-taking Simulation',
  },
  {
    text: 'ATLAS is able to really replicate quite a varied range of emotions and present some complexities that were quite unexpected',
    avatarName: 'Pharmacy Student, Monash University',
    avatarDesignation: 'History-taking Simulation',
  },
  {
    text: 'As someone who was not born and raised in an English-speaking country this experience is invaluable and necessary. Because of cultural differences it is difficult to predict what questions our parents might ask and how we might perform better in the face of these questions. This exercise has been a great reference in my thinking, conversational fluency, word accuracy, and even body language.',
    avatarName: 'Education Student, Monash University',
    avatarDesignation: 'Parent-Teacher Consultation Simulation',
  },
  {
    text: 'I was able to see a huge improvement in my performance based on the feedback provided and I felt more comfortable. I found that this platform was a fantastic way to consolidate knowledge and helped me to see which areas I had improved and which areas needed closer attention.',
    avatarName: 'Nutrition Student, Monash University',
    avatarDesignation: 'Clinical Consultation Simulation',
  },
  {
    text: 'talking to the patient made me realise that i need to practice talking to people more. i knew i would be nervous but i didnt realise how much i would be. talking to her has improved my confidence and made me think of the questions i need to ask (and it allows mistakes and me to learn from them).',
    avatarName: 'Nutrition Student, Monash University',
    avatarDesignation: 'Clinical Consultation Simulation',
  },
  {
    text: 'I would definitely use it to improve my confidence and ability to communicate student performance to the parents to collaborate with them.',
    avatarName: 'Education Student, Monash University',
    avatarDesignation: 'Parent-Teacher Consultation Simulation',
  },];

const postData = [
  {
    thumbnailSrc: '/images/creative-agency/post_1.jpeg',
    title: 'How ATLAS is Revolutionizing Corporate Training',
    date: '07 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_2.jpeg',
    title: 'Bridging the Gap Between Theory and Practice',
    date: '22 Apr 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_3.jpeg',
    title: 'The Future of AI in Education',
    date: '13 May 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_4.jpeg',
    title: 'Enhancing Learning with AI-Powered Simulations',
    date: '15 Mar 2023',
    url: '/blog/blog-details',
  },
];

export default function AtlasLandingPage() {
  pageTitle('ATLAS | AI-Powered Simulations for Education and Corporate Training');
  return (
    <>
      <HeroStyle5
        highlightTitle="Bridging Theory and Practice"
        title="ATLAS: Authentic Teaching and Learning Application Simulations"
        subTitle="Empower your students and professionals with personalised, expert-designed, AI-powered skills training."
        btnText="Request a Demo"
        btnUrl="/contact"
        videoSrc="https://www.youtube.com/embed/EHkK7S2P-nE"
        videoBtnText="Watch Video Overview"
      />
      <div className="cs_primary_bg">
        <Spacing lg="95" md="60" />
        <div className="container">
          <BrandCarousel data={brandData} />
        </div>
        <Spacing lg="95" md="60" />
      </div>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Revolutionize Learning with ATLAS"
            subTitle="Key Features"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_1.svg"
                title="AI-Powered Simulations"
                subTitle="Realistic, expert-developed scenarios that mimic professional interactions."
                btnText="Learn More"
                btnUrl="/features.pdf"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_2.svg"
                title="Real-Time Analysis and Feedback"
                subTitle="Immediate, personalized insights to enhance learning."
                btnText="Learn More"
                btnUrl="/features.pdf"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_3.svg"
                title="Multi-Modal Interaction"
                subTitle="Engage through audio, visual, and text-based formats."
                btnText="Learn More"
                btnUrl="/features.pdf"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_4.svg"
                title="Adaptive Learning Pathways"
                subTitle="Customized experiences based on user performance."
                btnText="Learn More"
                btnUrl="/features.pdf"
              />
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      {/* <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeadingStyle2
            title="Who Can Benefit from ATLAS?"
            subTitle="Target Audience"
            btnText="Find Solutions for Your Industry"
            btnUrl="/solutions"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6">
              <Card
                thumbnailSrc="/images/tech-startup/higher_education.jpeg"
                title="Higher Education Institutions"
                content="Universities and colleges offering professional degree programs."
                miniTitle="Education"
              />
            </div>
            <div className="col-lg-6">
              <Card
                thumbnailSrc="/images/tech-startup/corporate_training.jpeg"
                title="Corporations and Businesses"
                miniTitle="Corporate Training"
              />
            </div>
            <div className="col-lg-6">
              <Card
                thumbnailSrc="/images/tech-startup/students.jpeg"
                title="Students and Professionals"
                miniTitle="Learners"
              />
            </div>
            <div className="col-lg-6">
              <Card
                thumbnailSrc="/images/tech-startup/hr_professionals.jpeg"
                title="HR and L&D Professionals"
                miniTitle="Human Resources"
              />
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section> */}
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Unlock the Full Potential of Learning"
            subTitle="Key Benefits"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/icon4.png"
                title="Enhanced Professional Preparation"
                subTitle="Ready individuals for real-world challenges."
                href="/benefits#professional-preparation"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/icon5.png"
                title="Increased Confidence"
                subTitle="Build assurance in professional interactions."
                href="/benefits#increased-confidence"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/icon6.png"
                title="Improved Communication Skills"
                subTitle="Develop essential interpersonal abilities."
                href="/benefits#communication-skills"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/icon3.png"
                title="Better Knowledge Retention"
                subTitle="Reinforce learning through practical application."
                href="/benefits#knowledge-retention"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/icon2.png"
                title="Cost-Effective Training"
                subTitle="Efficient alternative to traditional methods."
                href="/benefits#cost-effective"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/icon1.png"
                title="Consistent Training Experiences"
                subTitle="Uniform learning across teams and locations."
                href="/benefits#consistent-training"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=xtI4GE3L9hQ"
          bgUrl="/images/tech-startup/video_bg.jpeg"
          title="Experience ATLAS in Action"
          titleVariant="cs_outline_text"
        />
      </div>
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" />
      <TestimonialSliderStyle2 data={testimonialData} />
      <section className="cs_shape_animation_2">
        <div className="cs_shape_1 position-absolute">
          {/* SVG or decorative shapes can be placed here */}
        </div>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Flexible Pricing Solutions for Your Needs"
            subTitle="Our Pricing"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
        </div>
        <div className="container">
          <PricingTableList />
        </div>
        <div className="cs_height_150 cs_height_lg_80" />
      </section>
      {/* <section className="cs_p76_full_width cs_gray_bg_2">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Stay Updated with Our Latest News"
            subTitle="Our Blog"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
        <Spacing lg="150" md="80" />
      </section> */}
      <section>
        <Spacing lg="150" md="80" />
        <div className="container">
          <Cta
            title="Ready to Transform Learning?"
            btnText="Request a Demo"
            btnUrl="/contact"
            bgUrl="/images/tech-startup/cta_bg.png"
            noShape
          />
        </div>
        <Spacing lg="150" md="80" />
      </section>
    </>
  );
}